<template>
    <div class="p-reset-create">
        <h1>重置密码</h1>
        <el-form
            :model="form"
            :rules="rules"
            ref="form"
            label-width="55px"
            v-loading="isLoading"
        >
            <el-form-item label="邮箱:" prop="email">
                <el-input v-model="form.email"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="send">发送</el-button>
                <el-button @click="back">返回</el-button>
            </el-form-item>
        </el-form>
        <div>系统将自动生成一个随机密码，并发送至你的邮箱，请注意查收。</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isLoading: false,
            form: {
                email: "",
            },
            rules: {
                email: [
                    {
                        required: true,
                        message: "请填写登录邮箱",
                        trigger: "blur",
                    },
                    {
                        min: 13,
                        max: 64,
                        message: "请输入正确的邮箱地址",
                        trigger: "blur",
                    },
                    {
                        validator: function (rule, value, callback) {
                            if (!/.+@mmldigi.com$/.test(value)) {
                                callback(
                                    new Error("仅支持 @mmldigi.com 的邮箱")
                                );
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    computed: {},
    mounted() {},
    methods: {
        send() {
            let that = this;
            that.$refs["form"].validate((valid) => {
                if (!valid) {
                    return;
                }

                if (that.isLoading) {
                    return;
                }
                that.isLoading = true;
                that.$ajax("/account/reset", that.form)
                    .done(function (d) {
                        console.log("done", d);
                        that.$alert("密码已成功重置，请查看邮件。").then(
                            function () {
                                that.$router.push({ name: "Home" });
                            }
                        );
                    })
                    .fail(function (err) {
                        console.log("fail", err);
                    })
                    .always(function () {
                        console.log("always");
                        that.isLoading = false;
                    });
            });
        },
        back() {
            this.$router.push({ path: "/" });
        },
    },
};
</script>

<style lang="less">
.p-reset-create {
    max-width: 375px;
    margin: 0 auto;
    padding: 0 10px;

    h1 {
        text-align: center;
        font-size: 36px;
        padding: 20px 0 40px 0;
    }
}
</style>
